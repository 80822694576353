import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

function Introduction() {
  return (
    <Container className="my-5" style={{ direction:'rtl', fontFamily:'BKoodakBold'}} >
      <Row className="align-items-center">
        <Col md={6} className="text-center text-md-right mb-4 mb-md-0">
          <h2>
           اپلیکیشن ارشدیار
            </h2>
          <p>
            به نرم‌افزار یادگیری ارشدیار خوش آمدید! این پلتفرم جامع شامل بانک سوالات پنج سال اخیر کارشناسی ارشد پرستاری با پاسخ تشریحی کامل به همراه نکات کلیدی کنکور سال‌های گذشته است. برخی از امکانات این نرم‌افزار شامل بخش شبیه‌ساز آزمون است که با استفاده از سوالات آزمون‌های کارشناسی ارشد سال‌های گذشته آزمون برگزار می‌کند. همچنین اینجا می‌توانید نکات طلایی کنکور را در بخش مربوطه مشاهده کنید.
          </p>
          <p>
            با امکانات اشتراک‌گذاری، بوک‌مارک، و قابلیت استفاده آنلاین، ما سعی کرده‌ایم تا تجربه یادگیری شما را تا حد امکان ساده و دلپذیر کنیم. اینجا به شما این امکان را می‌دهیم که به راحتی در هر زمان و هر مکان، برای آزمون کارشناسی ارشدتان آماده شوید.
          </p>
        </Col>
        <Col md={6} className="text-center">
          <Button variant="primary" size="lg">
            شروع به یادگیری برای کنکور کارشناسی ارشد
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default Introduction;
