import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Download from './components/Download';
import Introduction from './components/Introduction';
import Header from './components/Header';
import Footer from './components/Footer';
import SignUp from './components/SignUp';
import bodyimage from './components/images/body.jpg';
import footerbackground from "./components/images/footer.jpg"
import "./App.css";
import { Container, Navbar, Nav } from 'react-bootstrap';
import SignIn from './components/SignIn';

function App() {
  return (
    <Router >
    
        <Header />
   
      <div>
        <Container style={{ marginTop: '20px', marginBottom: '20px' }}>
          <Routes>
            <Route path="/download" element={<Download />} />
            <Route path="/" element={<Introduction />} />
          </Routes>
        </Container>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
