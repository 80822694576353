import { Container, Navbar, Nav } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import './header.css';

function Header() {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  
  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenSize]);

  return (
    
      <Navbar bg='primary'  expand="lg" style={{ fontFamily:'BKoodakBold', fontSize: 24,margin:5, direction:'rtl', borderRadius:45,}}>
        <Container >

        <Navbar.Brand style={{alignItems:'center', alignContent:'center'}} >
          <div style={{borderRadius:45, backgroundColor:"lightblue" , paddingTop:0, paddingLeft:5, paddingRight:5}} >

          
            <p style={{ fontFamily:"BKamranBold", alignSelf:'center', direction:'rtl', fontSize:30}}>
              ارشدیار
            </p>
            </div>
          </Navbar.Brand>

      
          
      
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
         
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto" dir="rtl">
              <Nav.Link as={Link} to="/" dir="rtl">
                معرفی نرم‌افزار
              </Nav.Link>
              <Nav.Link as={Link} to="/download" dir="rtl">
                دانلود
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
         
        </Container>
      </Navbar>
   
  );
}

export default Header;
