import React from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { FaInstagram, FaTelegram, FaTwitter } from 'react-icons/fa';
import footerbackground from "./images/footer.jpg";

function Footer() {
  const currentYear = new Date().getFullYear(); // دریافت سال فعلی

  return (
    <Navbar bg="light" expand="lg" fixed="bottom" style={{  direction: 'rtl', backgroundImage: `url(${footerbackground})`, fontFamily:'BKoodakBold',borderRadius:45, }}>
      <Container>
        <Nav className="me-auto" style={{ direction: 'rtl' }}>
          <Nav.Link href="#" className="ms-auto">
            © {currentYear} ارشدیار - تمامی حقوق محفوظ است.
          </Nav.Link>
          <Nav.Link href="https://instagram.com/arshadyarr">
            <FaInstagram />
          </Nav.Link>
          <Nav.Link href="https://telegram.me/arshadyar0">
            <FaTelegram />
          </Nav.Link>
          <Nav.Link href="https://twitter.com">
            <FaTwitter />
          </Nav.Link>
        </Nav>
      </Container>
    </Navbar>
  );
}

export default Footer;
