import { Container, Row, Col, Button } from 'react-bootstrap';

function Download() {
  return (
    <Container>
      <Row>
        <Col style={{flexDirection:'row', direction:"rtl"}} >
          <h2 style={{direction:'rtl', fontFamily:"BYekanBold"}} >دانلود نرم‌افزار</h2>
          <p style={{direction:'rtl', fontFamily:'Samim-Bold', justifySelf:'auto'}} >
            برای دانلود ارشدیار از استور اندرویدی مایکت بر روی لینک زیر کلیک کنید. لطفا توجه داشته باشید که استور اندرویدی مایکت باید از قبل بر روی 
            تلفن هوشمند شما نصب باشد و همچنین در حال حاضر اپلیکیشن ارشدیار تنها در روی گوشی های هوشمند با سیستم عامل اندروید قابل نصب است.


            </p>
          <Button style={{direction:'rtl' }} variant="primary" href="https://myket.ir/app/com.arshadyar">دانلود</Button>
        </Col>
      </Row>
    </Container>
  );
}

export default Download;
